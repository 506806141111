import React from 'react';
import Iframe from 'react-iframe'
import "./easy-week.scss"


const EasyWeek = () => {
    return (
        <div className="easy-week">
            <div className="easy-week__overlay"> </div>
            <Iframe url="https://dikidi.ru/1726426?p=0.pi"
                    title="myFrame"
                    id="myFrame"
                    height="100%"
                    width="85%"
                    className="easy-week__container"
                    frameBorder="0"
                    scrolling="yes"
            />
        </div>
    );
};

export default EasyWeek;