import React, {useState} from 'react';
import Title from "../title";
import od from "./od.png"
import pr from "./pr.png"
import mn from "./mn.png"
import per from "./per.png"
import mass from "./massage.png"


import "./services.scss"

const Services = () => {
    const [value, setValue] = useState("0");

    function changeHandler(event) {
        setValue(event.target.value);
    }

    return (
        <div className='service'>
            <div className='contain'>
                <Title title={'Сеанс'}/>
                <form className='service__block'>
{/*                    <label className='service__item'>
                        <input className='service__input' type="radio" name="service" value='1' checked={value === '1' } onChange={changeHandler}/>
                        <img className='service__img' src={per} alt=""/>
                        {value !== '1' && <div className="service__text">Первое погружение</div>}
                        {value === '1' && <span className="service__subtext">Флоатинг - новый для Беларуси вид СПА. Возможно, вы уже слышали про удивительный эффект расслабления и отдыха в флоат-студиях от знакомых. Или встречали упоминание в сериалах или прессе. Уже думали попробовать, но так и не решились? Тогда пробный 30 минутный сеанс "Лайт" для вас! </span>}
                    </label>*/}
                    <label className='service__item'>
                        <input className='service__input' type="radio" name="service" value='2'  checked={value === '2' } onChange={changeHandler}/>
                        <img className='service__img' src={od} alt=""/>
                        {value !== '2' && <div className="service__text">Одиночный сеанс</div>}
                        {value === '2' && <span className="service__subtext">БЫТЬ НАЕДИНЕ С СОБОЙ - САМЫЙ ВАЖНЫЙ НАВЫК, КОТОРЫЙ МЫ ПОТЕРЯЛИ .
Регулярная практика флоатинга позволит Вам вернуть себе себя. Вы заметите, что быть наедине с собой не так уж плохо. Вы лучше узнаете себя. Увидите вещи такими, какие они есть. Научитесь обращать внимание на то, чего обычно не замечаете.
В одиночестве мысли иногда сворачивают в неприятное русло. Особенно когда вы задумываетесь о своих чувствах, сомнениях и надеждах. И всё же это полезнее, чем неосознанно избегать таких размышлений.
Без понимания себя не будет и фундамента, на котором строится остальная жизнь. Возможно, умение быть наедине с собой не решит все проблемы, но оно поможет начать.....</span>}
                    </label>
                    <label className='service__item'>
                        <input className='service__input' type="radio" name="service" value='3'  checked={value === '3' } onChange={changeHandler}/>
                        <img className='service__img' src={pr} alt=""/>
                        {value !== '3' && <div className="service__text">Парный сеанс</div>}
                        {value === '3' && <span className="service__subtext">Парный флоатинг – это процедура, которая поможет не только семейным отношениям. В Европе и Америке выделяют 2 вида парного флоатинга – «couple floating» и «double flouting». На «couple floating» вы приходите со своим любимым человеком. На «double flouting» вы можете прийти со своим родственником, родителем, ребенком или другом/подругой. С кем вы придете – ваш личный выбор, кому предложить уникальное и полезно времяпровождение.</span>}
                    </label>
                    <label className='service__item'>
                        <input className='service__input' type="radio" name="service" value='4'  checked={value === '4'} onChange={changeHandler}/>
                        <img className='service__img' src={mass} alt=""/>
                        {value !== '4' && <div className="service__text">Классический массаж</div>}
                        {value === '4' && <span className="service__subtext">Классический массаж - это ручной массаж по всей поверхности тела и отдельных его зон. Этот массаж носит как расслабляющий так и тонизирующий характер</span>}
                    </label>
                    <label className='service__item'>
                        <input className='service__input' type="radio" name="service" value='5'  checked={value === '5' } onChange={changeHandler}/>
                        <img className='service__img' src={mn} alt=""/>
                        {value !== '5' && <div className="service__text">Постоянная скидка на сеанс для многодетных мам и беременных</div>}
                        {value === '5' &&<span className="service__subtext">Британская ассоциация СПА всем беременным женщинам рекомендует посещать флоат-процедуры. Врачи-терапевты предписывают именно такой вид отдыха будущим матерям. Они уверены – необходимо направить все силы на то, чтобы ребенок еще в утробе матери развивался нормально, был окружен добротой, спокойствием и любовью. Нельзя калечить еще не рожденное дитя стрессами. Нельзя сваливать на него Вашу усталость, бессонницу, раздражение и скверное настроение. Сегодня сделать это легко. Надо просто пойти и записаться на сеанс флоатинга. И ходить хотя бы 1 раз в неделю. Хорошая новость: флоатинг абсолютно безопасен в течение всех девяти месяцев беременности.</span>}
                    </label>
                </form>
            </div>
        </div>
    );
};

export default Services;