import nev1 from "./price-img/nevesomost/1.png"
import nev2 from "./price-img/nevesomost/2.png"
import nev3 from "./price-img/nevesomost/3.png"
import nev4 from "./price-img/nevesomost/4.png"
import nev5 from "./price-img/nevesomost/5.png"
import parn1 from "./price-img/parn/1.png"
import parn2 from "./price-img/parn/2.png"
import parn3 from "./price-img/parn/3.png"
import parn4 from "./price-img/parn/4.png"
import parn5 from "./price-img/parn/5.png"
import massage_standart1 from "./price-img/massage/standart/1.png"
import massage_standart2 from "./price-img/massage/standart/2.png"
import massage_standart3 from "./price-img/massage/standart/3.png"
import massage_standart4 from "./price-img/massage/standart/4.png"
import massage_standart5 from "./price-img/massage/standart/5.png"
import massage_standart6 from "./price-img/massage/standart/6.png"
import massage_mega1 from "./price-img/massage/mega/1.png"
import massage_mini1 from "./price-img/massage/mini/1.png"

/*import light1 from "./price-img/light/1.png"
import light2 from "./price-img/light/2.png"
import ultra1 from "./price-img/ultra/1.png"
import ultra2 from "./price-img/ultra/2.png"
import mega1 from "./price-img/mega/1.png"
import mega2 from "./price-img/mega/2.png"
import all1 from "./price-img/all/1.png"
import all2 from "./price-img/all/2.png"
import all3 from "./price-img/all/3.png"

*/

const PRICE = [
    { id:1, name:"ПОГРУЖЕНИЕ 30", price:75, description:"Парный или одиночный флоатинг 30 минут",
        images:[
            {id:1,img:nev1},
            {id:2,img:nev2},
            {id:3,img:nev3},
            {id:4,img:nev4},
            {id:5,img:nev5},
        ]},
    { id:2, name:"ПОГРУЖЕНИЕ 60", price:110, description:"Парный или одиночный флоатинг 60 минут",
        images:[
            {id:1,img:parn1},
            {id:2,img:parn2},
            {id:3,img:parn3},
            {id:4,img:parn4},
            {id:5,img:parn5},
        ]},

    { id:7, name:"Классический массаж \"МИНИ\" мужской", price:40, description:"Классический релакс массаж продолжительностью 30 минут",
        images:[
            {id:1,img:massage_mini1},
        ]},
   { id:7, name:"Классический массаж \"МИНИ\" женский", price:35, description:"Классический релакс массаж продолжительностью 30 минут",
        images:[
            {id:1,img:massage_mini1},
        ]},
   { id:8, name:"Классический массаж \"СТАНДАРТ\" мужской", price:65, description:"Классический релакс массаж продолжительностью 1 час",
        images:[
            {id:1,img:massage_standart1},
            {id:2,img:massage_standart2},
            {id:3,img:massage_standart3},
            {id:4,img:massage_standart4},
            {id:5,img:massage_standart5},
            {id:6,img:massage_standart6},
        ]},
    { id:9, name:"Классический массаж \"СТАНДАРТ\" женский", price:60, description:"Классический релакс массаж продолжительностью 1 час",
        images:[
            {id:1,img:massage_mega1},
        ]},

]

export  default PRICE;
