import React from 'react';
import Title from "../title";
import './price.scss'
import s1 from "./sertif.jpg"
import PRICE from "../../data/priceList";

const Price = () => {
    return (
        <div className='price'>
            <div className='contain'>
            <Title title={"Стоимость сеансов"}/>
            <div className='price__block'>
                <div className='price__item'>
                    <ul className='price__list'>
                        {PRICE.map(({id, name,price, description}) => {
                            return <li key={id} className='price__point'>
                                <div className='price__box'>
                                    <span className='price__text'>{name}</span>
                                    <span  className='price__text price__text--little'>{description}</span>
                                </div>
                                <div className='price__text price__text--bold'>{price}р</div>
                            </li>
                        })}
                        <p className='price__text'>**многодетным и беременным скидка 10% на сеансы флоатинга</p>
                        <p className='price__text'>***на ранее приобретенные сертификаты до 17.11.2024 при посещении выдаются халаты, полотенца, беруши и тапочки; на приобретенные сертификаты после 17.11.2024 при посещении выдаются только беруши</p>

                    </ul>
                </div>
                <div className='price__item'>
                    <div className='price__img'><img src={s1} alt=""/></div>
                    <p className='price__text'>На подарочный сертификат срок действия 3 месяца</p>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Price;