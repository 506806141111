import React from 'react';
import "./blog.scss"
import Title from "../../components/title";

const Blog = () => {
    return (
        <div className='blog'>
            <Title title="О Флоатинге"/>
            <div className='blog__block contain'>

                <div className="blog__text">
                    <div className='blog__description'>
                        <ul className='blog__list'>
                            <p className='blog__title'>Памятка</p>
                            <li>Для посещения сеанса флоатинга, пожалуйста, возьмите с собой следующее (аналогично тому, что вы берёте в баню или аквапарк):</li>
                            <ol className='blog__ol'>
                                <li>Полотенце (для душа до и после сеанса)</li>
                                <li>Купальник или плавки (по желанию, в кабине можно находится обнаженным)</li>
                                <li>Средства личной гигиены (шампунь, гель для душа и т.д.)</li>
                                <li>Тапочки (для удобства и гигиены)</li>
                                <li>Халат (по желанию)</li>
                            </ol>
                            <li>Не брейтесь и не делайте эпиляцию в день флоатинга. Флоатинг проходит в крепком растворе английской соли, и хотя она существенно меньше «щиплется», чем обычная поваренная соль, свежие ранки могут быть весьма чувствительными в течение некоторого времени.</li>
                            <li>Обязательно сходите в туалет перед процедурой.</li>
                            <li>Не наедайтесь и не напивайтесь перед процедурой, но и не идите на нее голодными.</li>
                            <li>Не употребляйте алкоголь и наркотики перед сеансом флоатинга!</li>
                            <li>Примите душ и вымойте голову перед сеансом.</li>
                            <li>Расслабляйтесь, расслабляйтесь, расслабляйтесь. Основной очаг напряжения при флоатинге — шея. Человек инстинктивно боится отпустить шею, так как боится утонуть. Не волнуйтесь — плотность раствора существенно больше плотности человеческого тела (включая голову), так что она будет плавать как пробка на поверхности воды. Смело расслабляйте шею — и вы получите от сеанса
                                флоатинга массу удовольствия!
                            </li>
                            <li>Не трите глаза руками! Если лицо запотело и пот попал в глаза — поморгайте несколько раз активно, но не трогайте их руками! Это — гарантированный способ окончить ваш сеанс раньше времени, или по крайней мере доставить несколько неприятных минут.</li>
                            <li>Используйте беруши для ушей (выдаются). Не вставляйте их полностью — пусть торчит небольшой кусочек, за который можно будет их вынуть.</li>
                            <li>Флоатиться лучше всего обнаженным. Одежда мешает.</li>
                            <li>При флоатинге не делайте резких движений, так как вас будет бросать туда сюда как шарик от пинпонга (не забудьте, вы будете практически в невесомости). Оптимальнее всего вытянуть руки в стороны и разместиться по центру камеры, после чего аккуратно и без резких движений убрать руки. Руки лучше всего размещать как будто на подлокотниках кресла, по бокам от тела, как
                                будто опираясь на воду.
                            </li>
                            <li>Перед тем, как выходить из флоат камеры постойте немного, давая раствору стечь.</li>
                            <li>Не ожидайте ничего. Идите на флоатинг с открытым умом, и вы получите массу незабываемых ощущений. Ожидания имеют тенденцию не сбываться, и вызывать разочарование.</li>
                            <br/>Соблюдая эти нехитрые правила, вы избавите себя от лишних проблем и сможете получить максимум удовольствия от этой замечательной процедуры — флоатинга.
                        </ul>
                    </div>

                    <div className='blog__description'>

                        <p className='blog__title'>Флоатинг</p>
                        — это необычная процедура для релакса, которая помогает избавиться от стресса, улучшить сон, облегчить мышечные боли и укрепить иммунитет.
                        Флоатинг проводится в специальной камере сенсорной депривации, которая изолирует человека от любых ощущений извне.
                        В камере находится мини-бассейн с соленой водой, в которой можно просто лежать, не предпринимая никаких усилий, чтобы удержаться на поверхности.
                        Вода имеет температуру, близкую к температуре тела, поэтому чувство границы между телом и водой исчезает.
                        В камере нет звуков, света и запахов, поэтому человек погружается в состояние глубокого расслабления и медитации.
                    </div>

                    <div className='blog__description'>
                        Камера сенсорной депривации была изобретена в 1954 году американским ученым Джоном Лилли, который изучал влияние изоляции на человеческий мозг.
                        Он обнаружил, что при отсутствии внешних раздражителей мозг начинает производить больше эндорфинов — гормонов счастья, которые улучшают настроение, снимают болевые ощущения и стимулируют творческое мышление.
                        Кроме того, флоатинг способствует увеличению уровня магния в организме, который необходим для нормальной работы нервной, сердечно-сосудистой, мышечной и костной систем.
                    </div>

                    <div className='blog__description'>
                        Для флоатинга используется специальная соль Эпсома, которая состоит из сульфата магния.
                        Это естественный минерал, который обладает противовоспалительными и регенеративными свойствами.
                        Соль Эпсома впитывается через кожу и помогает уменьшить мышечное напряжение, снять отеки, очистить поры, улучшить цвет лица и укрепить волосы и ногти.
                        Соль Эпсома также способствует выводу токсинов из организма, улучшению кровообращения и нормализации кровяного давления.
                    </div>

                    <div className='blog__description'>
                        <ul className="about__list"><p className='blog__title'>Флоатинг можно рассматривать с разных точек зрения: как косметологическую, физическую или психологическую процедуру.</p>
                            <li>С точки зрения косметологии, флоатинг помогает улучшить состояние кожи, волос и ногтей, придать им здоровый вид и сияние.
                            </li>
                            <li>С точки зрения физиологии, флоатинг помогает расслабить мышцы, суставы и позвоночник, снять напряжение и боль, ускорить восстановление после травм и занятий спортом.
                            </li>
                            <li>С точки зрения психологии, флоатинг помогает справиться со стрессом, тревогой, депрессией, бессонницей и другими психическими проблемами.
                            </li>
                            <p className='blog__phrase'>Флоатинг помогает восстановить гармонию между телом и душой, улучшить самооценку и самоконтроль, развить интуицию и креативность.</p>
                        </ul>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Blog;