import React from 'react';
import './footer.scss'

const Footer = () => {
    return (
        <div className='footer contain'>
            <div>2024 Helix Float Studio</div>
            <div>ИП Медведев Игорь Александрович</div>
        </div>
    );
};

export default Footer;