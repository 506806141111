import React from 'react';
import "./news.scss"
import Title from "../../components/title";
import certificate from "./s.png";
import ab1 from "./ab_float.PNG";


const News = () => {
    return (
        <div className='news'>
            <Title title='Сертификаты и абонементы'/>
            <div className='news__block contain'>
                <div className='news__item'>
                    <img className='news__img news__img--norevers' src={certificate} alt=""/>
                    <dl className='news__text'>
                        <dt className='news__title'>Сертификаты</dt>
                        <dd className='news__description'>
                            <div className="news__definition">Хотите подарить в подарок самую необычную, самую расслабляющую СПА процедуру?</div>
                            <div className="news__phrase">Сертификат на флоатинг – это подарок, который дает возможность избавиться от стресса, улучшить кровообращение и сон, повысить иммунитет и творческий потенциал. Кроме того, сертификат на флоатинг может быть приятным сюрпризом для близких или любимых людей.</div>
                            <ul className="news__list"><p>Варианты для подарка:</p>
                                <li> 110 рублей час флоатинга</li>
                                <li> 75 рублей пол часа флоатига</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className='news__item'>
                    <img className='news__img news__img--revers' src={ab1} alt=""/>
                    <dl className='news__text'>
                        <dt className='news__title'>Абонементы</dt>
                        <dd className='news__description'>
                            <ul className="news__list"><p>Мы можем предложить абонементы на одиночный флоатинг:</p>
                                <li>380 рублей 4 раза</li>
                                <li>480 рублей 6 раз</li>
                                <li>560 рублей 8 раз</li>
                            </ul>
                            <div className="news__phrase">Абонементы на флоатинг могут быть приобретены как для себя, так и в подарок
                            </div>
                        </dd>
                    </dl>
                    <img className='news__img ' src={ab1} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default News;